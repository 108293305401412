import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { CartItem } from "../models/cart";
import * as cartService from "../services/cart";
import { actions as orderActions } from "./orders";
import { actions as authActions } from "./auth";
import { ApplicationState, store } from "./store";
import { actions as wihsListActions } from "./wishList";
import { isArray } from "lodash";

export type State = {
  data: CartItem[] | null;
  sideCartOpen: boolean;
};

const sideCartView = Object.assign(
  {},
  {
    success: createActionCreator("@@CART/SIDE_CART_OPEN", resolve => (sideCartOpen: boolean) => resolve(sideCartOpen)),
  }
);

const fetchCartItems = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { cart } = getState();

    if (cart.data) {
      return;
    }

    try {
      const data = await cartService.getCartItems();
      dispatch(fetchCartItems.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@CART/SUCCESS", resolve => (cart: CartItem[]) => resolve(cart)),
  }
);

const addDiamondToCart = Object.assign(
  (itemId: number, wishedItemId?: number) => async (dispatch: Dispatch) => {
    try {
      const setName = store.getState().diamonds.all?.find(d => d.id === itemId)?.product.lineSet;
      if (!!setName) {
        const data = await cartService.addSetToCart(itemId, wishedItemId);
        dispatch(addDiamondToCart.success(data));
        return;
      }
      if (wishedItemId) {
        const data = await cartService.addDiamondToCart(itemId, wishedItemId);
        dispatch(addDiamondToCart.success(data));
      } else {
        const data = await cartService.addDiamondToCart(itemId);
        dispatch(addDiamondToCart.success(data));
      }
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@CART/ADD_DIAMOND/SUCCESS", resolve => (cartItem: CartItem | CartItem[]) =>
      resolve(cartItem)
    ),
  }
);

const addMeleeToCart = Object.assign(
  (itemId: number, numberOfStones?: number, carats?: number, wishedItemId?: number) => async (dispatch: Dispatch) => {
    try {
      const data = await cartService.addMeleeToCart(itemId, numberOfStones, carats, wishedItemId);
      dispatch(addMeleeToCart.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@CART/ADD_MELEE/SUCCESS", resolve => (cartItem: CartItem) => resolve(cartItem)),
  }
);

const addCompleteRingToCart = Object.assign(
  (totalPrice: number, wishedItemId: number, completedRingId: number) => async (dispatch: Dispatch) => {
    try {
      const data = await cartService.addCompletedRingToCart(totalPrice, wishedItemId, completedRingId);
      dispatch(addCompleteRingToCart.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@CART/ADD_COMPLETEDRING/SUCCESS", resolve => (cartItem: CartItem) =>
      resolve(cartItem)
    ),
  }
);

const addProductToCart = Object.assign(
  (itemId: number) => async (dispatch: Dispatch) => {
    try {
      const data = await cartService.addProductToCart(itemId);
      dispatch(addProductToCart.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@CART/ADD_PRODUCT/SUCCESS", resolve => (cartItem: CartItem) => resolve(cartItem)),
  }
);

const addEngagementRingToCart = Object.assign(
  (totalPrice: number, wishedItemId: number, engagementRingId: number) => async (dispatch: Dispatch) => {
    try {
      const data = await cartService.addEngagementRingToCart(totalPrice, wishedItemId, engagementRingId);
      dispatch(addEngagementRingToCart.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@CART/ADD_COMPLETEDRING/SUCCESS", resolve => (cartItem: CartItem) =>
      resolve(cartItem)
    ),
  }
);

const addEternityRingToCart = Object.assign(
  (wishedItemId: number, eternityRingId: number) => async (dispatch: Dispatch) => {
    try {
      const data = await cartService.addEternityRingToCart(wishedItemId, eternityRingId);
      dispatch(addEternityRingToCart.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@CART/ADD_ETERNITY_RING/SUCCESS", resolve => (cartItem: CartItem) =>
      resolve(cartItem)
    ),
  }
);

const addBraceletToCart = Object.assign(
  (wishedItemId: number, braceletId: number) => async (dispatch: Dispatch) => {
    try {
      const data = await cartService.addBraceletToCart(wishedItemId, braceletId);
      dispatch(addBraceletToCart.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@CART/ADD_BRACELET/SUCCESS", resolve => (cartItem: CartItem) => resolve(cartItem)),
  }
);

const bulkAddDiamondsToCart = Object.assign(
  (itemIds: number[]) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    try {
      const data = await cartService.bulkAddDiamondsToCart(itemIds);
      const { cart } = getState();
      const cartItemIds = (cart.data || []).map(ci => ci.itemId);
      const newItems = data.filter(ci => !cartItemIds.includes(ci.itemId));
      dispatch(bulkAddDiamondsToCart.success(newItems));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@CART/BULK_ADD_DIAMOND/SUCCESS", resolve => (cartItems: CartItem[]) =>
      resolve(cartItems)
    ),
  }
);

const bulkRemoveDiamondsFromCart = Object.assign(
  (itemIds: number[]) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    try {
      const data = await cartService.bulkRemoveDiamondsFromCart(itemIds);
      const newItems = data;
      dispatch(bulkRemoveDiamondsFromCart.success(newItems));
    } catch {
      console.log("catch");
      return;
    }
  },
  {
    success: createActionCreator("@@CART/BULK_REMOVE_DIAMOND/SUCCESS", resolve => (cartItems: CartItem[]) =>
      resolve(cartItems)
    ),
  }
);
// const bulkAddBasketToCart = Object.assign(
//   (diamonds: BulkMeleeType[]) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
//     try {
//       const data = await cartService.bulkAddBasketToCart(diamonds);
//       const newItems = data;
//       dispatch(bulkRemoveDiamondsFromCart.success(newItems));
//     } catch {
//       return;
//     }
//   },
//   {
//     success: createActionCreator("@@CART/BULK_REMOVE_DIAMOND/SUCCESS", resolve => (cartItems: CartItem[]) =>
//       resolve(cartItems)
//     ),
//   }
// );

const removeFromCart = Object.assign(
  (itemId: number) => async (dispatch: Dispatch) => {
    try {
      //Logic for removing sets from cart
      const productId = store.getState().wishList.data?.find(w => w.id === itemId)?.itemId;
      const setName = store.getState().diamonds.all?.find(d => d.id === (productId as number))?.product.lineSet;
      if (!!setName) {
        await cartService.deleteSet(itemId);
        const itemsToRemove = store
          .getState()
          .diamonds.all?.filter(d => d.product.lineSet === setName)
          .map(d => d.id);
        dispatch(removeFromCart.success(itemsToRemove as number[]));
        return;
      }
      await cartService.deleteCartItem(itemId);
      dispatch(removeFromCart.success(itemId));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@CART/REMOVE/SUCCESS", resolve => (itemId: number | number[]) => resolve(itemId)),
  }
);

const defaultState: State = {
  data: null,
  sideCartOpen: false,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(sideCartView.success, (state, action) =>
    produce(state, draft => {
      draft.sideCartOpen = action.payload;
    })
  ),
  handleAction(fetchCartItems.success, (state, action) =>
    produce(state, draft => {
      draft.data = action.payload;
    })
  ),
  handleAction([addProductToCart.success], (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).concat(action.payload);
    })
  ),
  handleAction(removeFromCart.success, (state, action) =>
    produce(state, draft => {
      if (isArray(action.payload)) {
        draft.data = (state.data || []).filter(i => !(action.payload as number[]).includes(i.itemId));
        return;
      }
      draft.data = (state.data || []).filter(i => i.itemId !== action.payload);
    })
  ),
  handleAction(bulkRemoveDiamondsFromCart.success, (state, action) =>
    produce(state, draft => {
      draft.data = action.payload;
    })
  ),

  handleAction(orderActions.createOrder.success, (state, action) =>
    produce(state, draft => {
      draft.data = [];
    })
  ),
  handleAction(authActions.logout.success, (state, action) =>
    produce(state, draft => {
      draft.data = [];
    })
  ),
  handleAction(wihsListActions.bulkRemoveDiamondsFromBasket.success, (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).filter(i => !action.payload.includes(i.wishedItemId as number));
    })
  ),
  handleAction(wihsListActions.removeFromWishList.success, (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).filter(i => action.payload !== i.itemId);
    })
  ),
  handleAction(wihsListActions.deleteDiamondsSetFromBasket.success, (state, action) =>
    produce(state, draft => {
      const remainingDiamonds = (state.data || []).filter(i => !action.payload.includes(i.itemId));
      draft.data = remainingDiamonds;
    })
  ),
]);

const actions = {
  addDiamondToCart,
  addProductToCart,
  addMeleeToCart,
  addCompleteRingToCart,
  addEngagementRingToCart,
  addEternityRingToCart,
  bulkAddDiamondsToCart,
  bulkRemoveDiamondsFromCart,
  fetchCartItems,
  removeFromCart,
  // bulkAddBasketToCart,
  addBraceletToCart,
  sideCartView,
};

export { actions, reducer };
