import { Dispatch } from "redux";
import { createActionCreator, createReducer } from "deox";
import { Diamond, RingSetting } from "../models/products";
import * as engagementRingService from "../services/engagementRingConfig";

export type State = {
  ringSetting: RingSetting | null;
  diamond: Diamond | null;
};

const getEngagementRingConfig = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const data = await engagementRingService.getUserEngagementRingConfig();

      const modifiedData = {
        ringSetting: data.ringSetting,
        diamond: data.diamond,
      };
      dispatch(getEngagementRingConfig.success(modifiedData));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ENGAGEMENT_RING/GET_ENGAGEMENT_SETTING", resolve => (data: State) => resolve(data)),
  }
);

const addRingSettingToEngagementConfig = Object.assign(
  (itemId: number | null) => async (dispatch: Dispatch) => {
    try {
      const data = await engagementRingService.updateUserRingConfig({
        ringSetting: {
          id: itemId,
        },
      });
      dispatch(addRingSettingToEngagementConfig.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ENGAGEMENT_RING/ADD_RING_SETTING", resolve => (data: void) => resolve(data)),
  }
);

const addDiamondToEngagementConfig = Object.assign(
  (stockId: string | null) => async (dispatch: Dispatch) => {
    try {
      const data = await engagementRingService.updateUserDiamondConfig({
        diamond: {
          stockId,
        },
      });
      dispatch(addDiamondToEngagementConfig.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ENGAGEMENT_RING/ADD_DIAMOND", resolve => (data: void) => resolve(data)),
  }
);

// const addDiamondToEngagementConfig = Object.assign(
//   (itemId: number) => async (dispatch: Dispatch) => {
//     try {
//       console.log(itemId);
//     } catch {
//       return;
//     }
//   },
//   {
//     success: createActionCreator("@@ENGAGEMENT_RING/ADD_DIAMOND", resolve => (diamond: Diamond) => resolve(diamond)),
//   }
// );

const defaultState: State = {
  ringSetting: null,
  diamond: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(getEngagementRingConfig.success, (state, action) => ({
    ...state,
    ringSetting: action.payload.ringSetting,
    diamond: action.payload.diamond,
  })),
]);

const actions = {
  getEngagementRingConfig,
  addRingSettingToEngagementConfig,
  addDiamondToEngagementConfig,
};

export { actions, reducer };
