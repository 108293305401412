import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { WishListItem } from "../models/cart";
import * as wishListService from "../services/wishList";
import { ApplicationState, store } from "./store";
import { actions as authActions } from "./auth";

export type State = {
  data: WishListItem[] | null;
};

const fetchWishListItems = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { wishList } = getState();

    if (wishList.data && wishList.data.length > 0) {
      return;
    }

    try {
      const data = await wishListService.getWishListItems();
      dispatch(fetchWishListItems.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/SUCCESS", resolve => (wishList: WishListItem[]) => resolve(wishList)),
  }
);

const addProductToWishList = Object.assign(
  (productId: number) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addProductToWishList(productId);
      dispatch(addProductToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_PRODUCT/SUCCESS", resolve => (wishListItem: WishListItem) =>
      resolve(wishListItem)
    ),
  }
);

const removeFromWishList = Object.assign(
  (itemId: number | number[]) => async (dispatch: Dispatch) => {
    try {
      await wishListService.deleteWishListItem(itemId as number);
      
      dispatch(removeFromWishList.success(itemId));
    } catch (error) {
      console.error("Error removing product from wishlist:", error);
    }
  },
  {
    success: createActionCreator("@@WISHLIST/REMOVE/SUCCESS", resolve => (itemId: number | number[]) =>
      resolve(itemId)
    ),
  }
);














const addEngagementRingToWishList = Object.assign(
  (body: any) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addEngagementRingToWishList(body);
      dispatch(addEngagementRingToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_ENGAGEMENT_RING/SUCCESS", resolve => (wishListItem: WishListItem) =>
      resolve(wishListItem)
    ),
  }
);

const addCompletedRingToWishList = Object.assign(
  (body: any) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addCompletedRingToWishList(body);
      dispatch(addCompletedRingToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_COMPLETED_RING/SUCCESS", resolve => (wishListItem: WishListItem) =>
      resolve(wishListItem)
    ),
  }
);

const addEternityRingToWishList = Object.assign(
  (id: number) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addEternityRingToWishList(id);
      dispatch(addEternityRingToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_ETERNITY_RING/SUCCESS", resolve => (wishListItem: WishListItem) =>
      resolve(wishListItem)
    ),
  }
);

const addBraceletToWishList = Object.assign(
  (id: number) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addBraceletToWishList(id);
      dispatch(addEternityRingToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_BRACELET/SUCCESS", resolve => (wishListItem: WishListItem) =>
      resolve(wishListItem)
    ),
  }
);

const addDiamondsFromSetToWishList = Object.assign(
  (itemIds: number[]) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addDiamondsFromSetToWishList(itemIds);
      dispatch(addDiamondsFromSetToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_DIAMONDS_SET/SUCCESS", resolve => (wishListItem: WishListItem[]) =>
      resolve(wishListItem)
    ),
  }
);

const bulkAddDiamondsToWishList = Object.assign(
  (itemIds: number[]) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    try {
      const data = await wishListService.bulkAddDiamondsToCart(itemIds);
      const { wishList } = getState();
      const wishListItemId = (wishList.data || []).map(ci => ci.itemId);
      const newItems = data.filter(ci => !wishListItemId.includes(ci.itemId));

      dispatch(bulkAddDiamondsToWishList.success(newItems));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/BULK_ADD_DIAMOND/SUCCESS", resolve => (wishListItems: WishListItem[]) =>
      resolve(wishListItems)
    ),
  }
);
const bulkRemoveDiamondsFromBasket = Object.assign(
  (wishedIds: number[]) => async (dispatch: Dispatch) => {
    try {
      await wishListService.bulkRemoveDiamondsFromBasket(wishedIds);
      dispatch(bulkRemoveDiamondsFromBasket.success(wishedIds));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/BULK_REMOVE_DIAMONDS/SUCCESS", resolve => (wishListItemIds: number[]) =>
      resolve(wishListItemIds)
    ),
  }
);



const deleteDiamondsSetFromBasket = Object.assign(
  (itemId: number | number[]) => async (dispatch: Dispatch) => {
    try {
      await wishListService.deleteDiamondsSetFromBasket(itemId as number);
      const allDiamonds = store.getState().diamonds.all;
      if (!allDiamonds) return;
      const setName = allDiamonds.find(diamond => diamond.id === itemId)?.product.lineSet;
      const findAllDiamondsFromSetIds = allDiamonds
        .filter(diamond => diamond.product.lineSet === setName)
        .map(d => d.id);
      dispatch(deleteDiamondsSetFromBasket.success(findAllDiamondsFromSetIds as number[]));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info("Could not delete the set: ", e);
    }
  },
  {
    success: createActionCreator("@@WISHLIST/REMOVEDIAMONDSSET/SUCCESS", resolve => (itemId: number[]) =>
      resolve(itemId)
    ),
  }
);
const removeDiamondFromBasketPage = Object.assign(
  (wishedItemId: number) => async (dispatch: Dispatch) => {
    try {
      await wishListService.deleteWishListItemFromBasket(wishedItemId as number);
      dispatch(removeDiamondFromBasketPage.success(wishedItemId as number));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info("Could not remove: ", e);
    }
  },
  {
    success: createActionCreator("@@WISHLIST/REMOVE_FROM_BASKET/SUCCESS", resolve => (itemId: number | number[]) =>
      resolve(itemId)
    ),
  }
);

const defaultState: State = { data: null };

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchWishListItems.success, (state, action) =>
    produce(state, draft => {
      draft.data = action.payload;
    })
  ),
  handleAction(
    [
      addProductToWishList.success,
      bulkAddDiamondsToWishList.success,
      addCompletedRingToWishList.success,
      addEngagementRingToWishList.success,
      addEternityRingToWishList.success,
      addBraceletToWishList.success,
    ],
    (state, action) =>
      produce(state, draft => {
        draft.data = (state.data || []).concat(action.payload);
      })
  ),
  handleAction(addDiamondsFromSetToWishList.success, (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).concat(action.payload);
    })
  ),
  handleAction(removeFromWishList.success, (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).filter(i => i.itemId !== action.payload);
    })
  ),
  handleAction(removeDiamondFromBasketPage.success, (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).filter(i => i.id !== action.payload);
    })
  ),
  handleAction(bulkRemoveDiamondsFromBasket.success, (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).filter(i => !action.payload.includes(i.id));
    })
  ),
  handleAction(deleteDiamondsSetFromBasket.success, (state, action) =>
    produce(state, draft => {
      const remainingDiamonds = (state.data || []).filter(i => !action.payload.includes(i.itemId));
      draft.data = remainingDiamonds;
    })
  ),

  handleAction(authActions.logout.success, (state, action) =>
    produce(state, draft => {
      draft.data = [];
    })
  ),
]);

const actions = {
  addProductToWishList,
  addCompletedRingToWishList,
  addDiamondsFromSetToWishList,
  addEngagementRingToWishList,
  addEternityRingToWishList,
  addBraceletToWishList,
  bulkAddDiamondsToWishList,
  bulkRemoveDiamondsFromBasket,
  fetchWishListItems,
  removeFromWishList,
  removeDiamondFromBasketPage,
  deleteDiamondsSetFromBasket,
};

export { actions, reducer };
