import { Dispatch } from "redux";
import { createActionCreator, createReducer } from "deox";
import * as genericDiamondsService from "../services/genericDiamonds";
import { GenericDiamond } from "../models/genericDiamond";

export type State = GenericDiamond[];

const defaultState: State = [];

const fetchGenericDiamonds = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await genericDiamondsService.getAllGenericDiamonds();
      dispatch(fetchGenericDiamonds.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@GENERIC_DIAMOND/FETCH", resolve => (genericDiamonds: GenericDiamond[]) =>
      resolve(genericDiamonds)
    ),
  }
);
 
const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchGenericDiamonds.success, (state, action) => action.payload),
]);

const actions = {
  fetchGenericDiamonds,
};

export { actions, reducer };
