import { createReducer, createActionCreator } from "deox";
import { GenericDiamond } from "../models/genericDiamond";
import { RingSetting } from "../models/products";

export type State = {
  singlePage: boolean;
  ringSetting: RingSetting | null;
  genericDiamond: GenericDiamond | null;
};

const singlePageView = Object.assign(
  {},
  {
    success: createActionCreator("@@COMPLETED_RING/SINGLE_PAGE_VIEW", resolve => (singlePage: boolean) =>
      resolve(singlePage)
    ),
  }
);

const selectRingSetting = Object.assign(
  {},
  {
    success: createActionCreator("@@COMPLETED_RING/SELECT_RING_SETTING", resolve => (ringSetting: RingSetting | null) =>
      resolve(ringSetting)
    ),
  }
);

const selectGenericDiamond = Object.assign(
  {},
  {
    success: createActionCreator(
      "@@COMPLETED_RING/SELECT_GENERIC_DIAMOND",
      resolve => (genericDiamond: GenericDiamond | null) => resolve(genericDiamond)
    ),
  }
);

const defaultState: State = {
  singlePage: false,
  ringSetting: null,
  genericDiamond: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(singlePageView.success, (state, action) => ({
    ...state,
    singlePage: action.payload,
  })),
  handleAction(selectRingSetting.success, (state, action) => ({
    ...state,
    ringSetting: action.payload,
  })),
  handleAction(selectGenericDiamond.success, (state, action) => ({
    ...state,
    genericDiamond: action.payload,
  })),
]);

const actions = {
  singlePageView,
  selectRingSetting,
  selectGenericDiamond,
};

export { actions, reducer };
