// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const pipe = <R>(fn1: (a: R) => R, ...fns: Array<(a: R) => R>) =>
  fns.reduce((prevFn, nextFn) => value => nextFn(prevFn(value)), fn1);

const dataUrlToBase64 = (dataUrl: string): string => dataUrl.replace(/^data:image\/(\w*);base64,/, "");

const removeEmptyStrings = (input: unknown): unknown => {
  const inputIndexable = input as { [index: string]: any };

  const result = Object.keys(inputIndexable).reduce((obj, key) => {
    const property = inputIndexable[key];
    if (typeof property === "object") {
      obj[key] = removeEmptyStrings(property);
    }

    obj[key] = property === "" ? null : property;
    return obj;
  }, {} as { [index: string]: unknown });

  return result;
};

const convertToLocale = (price: number | undefined): string | undefined => {
  if (!price) {
    return;
  }

  return price.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const truncateText = (text: string, windowsWidth?: number) => {
  if (text.length > 10 && windowsWidth && windowsWidth < 1600) {
    return `${text.substring(0, 9)}...`;
  }
  if (text.length > 14 && !windowsWidth) {
    return `${text.substring(0, 13)}...`;
  }

  return text;
};

export { dataUrlToBase64, pipe, removeEmptyStrings, convertToLocale, truncateText };
