import { connectRouter, RouterRootState, RouterState } from "connected-react-router";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import history from "../history";
import * as admin from "./admin";
import * as auth from "./auth";
import * as cart from "./cart";
import * as contact from "./contact";
import * as data from "./data";
import * as diamonds from "./diamonds";
import * as errors from "./errors";
import * as melee from "./melee";
import * as misc from "./misc";
import * as orders from "./orders";
import * as shows from "./shows";
import * as UIState from "./UIState";
import * as wishList from "./wishList";
import * as domain from "./domain";
import * as pushNotifications from "./pushNotifications";
import * as ringSettings from "./ringSettings";
import * as engagementRing from "./engagementRing";
import * as completedRing from "./completedRing";
import * as genericDiamonds from "./genericDiamonds";
import * as eternityRings from "./eternityRings";
import * as products from "./products";

export interface ApplicationState extends RouterRootState {
  admin: admin.State;
  auth: auth.State;
  cart: cart.State;
  contact: contact.State;
  data: data.State;
  diamonds: diamonds.State;
  errors: errors.State;
  UIState: UIState.State;
  melee: melee.State;
  misc: misc.State;
  router: RouterState;
  shows: shows.State;
  wishList: wishList.State;
  orders: orders.State;
  domain: domain.State;
  pushNotifications: pushNotifications.State;
  ringSettings: ringSettings.State;
  engagementRing: engagementRing.State;
  completedRing: completedRing.State;
  genericDiamonds: genericDiamonds.State;
  eternityRings: eternityRings.State;
  products: products.State;
}



const rootReducer = combineReducers<ApplicationState>({
  admin: admin.reducer,
  auth: auth.reducer,
  cart: cart.reducer,
  contact: contact.reducer,
  data: data.reducer,
  diamonds: diamonds.reducer,
  errors: errors.reducer,
  UIState: UIState.reducer,
  melee: melee.reducer,
  misc: misc.reducer,
  router: connectRouter(history),
  shows: shows.reducer,
  wishList: wishList.reducer,
  orders: orders.reducer,
  domain: domain.reducer,
  pushNotifications: pushNotifications.reducer,
  ringSettings: ringSettings.reducer,
  engagementRing: engagementRing.reducer,
  completedRing: completedRing.reducer,
  genericDiamonds: genericDiamonds.reducer,
 
  eternityRings: eternityRings.reducer,
 
  products: products.reducer,
});

const middlewares = [thunk];
const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

export { store };
export default (state: ApplicationState, action: any) => {
  rootReducer(action.type === "LOGOUT" ? undefined : state, action);
};
