import { produce } from "immer";
import { Dispatch } from "redux";
import { actions as messageActions } from "./messages";
import { createActionCreator, createReducer } from "deox";
import * as eternityRingService from "../../services/eternityRings";
import { EternityRing } from "../../models/products";
// import { GenericDiamond, UpsertGenericDiamond,  } from "../../models/genericDiamond";

export type State = EternityRing[];

const defaultState: State = [];

const fetchEternityRings = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await eternityRingService.getAllEternityRings();
      dispatch(fetchEternityRings.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ADMIN/ETERNITY_RINGS/FETCH", resolve => (data: EternityRing[]) =>
      resolve(data)
    ),
  }
);

// const editGenericDiamond = Object.assign(
//   (id: number | undefined, model: GenericDiamond) => async (dispatch: Dispatch) => {
//     try {
//       await genericDiamondsService.editGenericDiamond(id ? id : 0, model);
//       dispatch(editGenericDiamond.success({ ...model, id: id }));
//     } catch {
//       return;
//     }
//   },
//   {
//     success: createActionCreator("@@ADMIN/GENERIC_DIAMOND/EDIT", resolve => (data: GenericDiamond) => resolve(data)),
//   }
// );

// const addGenericDiamond = Object.assign(
//   (model: GenericDiamond) => async (dispatch: Dispatch) => {
//     try {
//       const result = await genericDiamondsService.addGenericDiamond(model);
//       dispatch(addGenericDiamond.success(result));
//     } catch {
//       return;
//     }
//   },
//   {
//     success: createActionCreator("@@ADMIN/GENERIC_DIAMOND/ADD", resolve => (data: GenericDiamond) => resolve(data)),
//   }
// );

const deleteAllEternityRings = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      await eternityRingService.deleteAllEternityRings()

      messageActions.commit("Delete successul. The page will reload to show the updated data", "info")(dispatch);

      setTimeout(() => window.location.reload(), 2000);
    } catch {
      messageActions.commit("Error Deleting", "error")(dispatch);
    }
  },
  {}
);

const importCsv = Object.assign(
  (file: File) => async (dispatch: Dispatch) => {
    try {
      await eternityRingService.importCsv(file);

      messageActions.commit("Import successul. The page will reload to show the updated data", "info")(dispatch);

      setTimeout(() => window.location.reload(), 2000);
    } catch {
      messageActions.commit("Error importing file", "error")(dispatch);
    }
  },
  {}
);
const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchEternityRings.success, (state, action) => action.payload),

  // handleAction(addGenericDiamond.success, (state, action) => state.concat(action.payload)),

  // handleAction(editGenericDiamond.success, (state, action) =>
  //   produce(state, draft => {
  //     const item = state.find(i => i.id === action.payload.id);
  //     if (!item) {
  //       return;
  //     }
  //     const itemIndex = draft.findIndex(i => i.id === action.payload.id);
  //     const newItem = {
  //       id: item.id,
  //       ...action.payload,
  //     };
  //     draft[itemIndex] = newItem;
  //     return draft;
  //   })
  // ),
]);

const actions = {
  fetchEternityRings,
  deleteAllEternityRings,
  // editGenericDiamond,
  // addGenericDiamond,
  importCsv,
};

export { actions, reducer };
