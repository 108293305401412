import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { Product, RingSetting, UpsertRingSetting } from "../../models/products";
import * as ringSettingService from "../../services/ringSettings";
import { actions as messageActions } from "./messages";
import { withErrorDispatch } from "./withErrorDispatch";

export type State = Product<RingSetting>[];

const defaultState: State = [];

const addRingSetting = Object.assign(
  (model: UpsertRingSetting) => async (dispatch: Dispatch) => {
    try {
      const result = await ringSettingService.addRingSetting(model);
      dispatch(addRingSetting.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@RINGSETTING/ADD", resolve => (ringSetting: Product<RingSetting>) =>
      resolve(ringSetting)
    ),
  }
);

const fetchRingSettings = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await ringSettingService.getAllRingSettings();
      dispatch(fetchRingSettings.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@RINGSETTING/FETCH", resolve => (ringSettings: Product<RingSetting>[]) =>
      resolve(ringSettings)
    ),
  }
);

const editRingSetting = Object.assign(
  (id: number, model: UpsertRingSetting) => async (dispatch: Dispatch) => {
    try {
      await ringSettingService.edit(id, model);
      dispatch(editRingSetting.success({ id, item: model }));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@RINGSETTING/EDIT", resolve => (data: { id: number; item: UpsertRingSetting }) =>
      resolve(data)
    ),
  }
);

const deleteRingSetting = Object.assign(
  (ringId: number) =>
    withErrorDispatch(
      ringSettingService.deleteRingSetting(ringId),
      () => deleteRingSetting.success(ringId),
      "Error deleting ring setting"
    ),
  {
    success: createActionCreator("@@ADMIN/RINGSETTING/DELETE", resolve => (ringId: number) => resolve(ringId)),
  }
);

const importCsv = Object.assign(
  (file: File) => async (dispatch: Dispatch) => {
    try {
      await ringSettingService.importCsv(file);

      messageActions.commit("Import successul. The page will reload to show the updated data", "info")(dispatch);

      setTimeout(() => window.location.reload(), 2000);
    } catch {
      messageActions.commit("Error importing file", "error")(dispatch);
    }
  },
  {}
);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(addRingSetting.success, (state, action) => state.concat(action.payload)),
  //   handleAction(changeMeleeStatus.success, (state, action) =>
  //     produce(state, draft => {
  //       const item = draft.find(i => i.id === action.payload.id);
  //       item && (item.isActive = action.payload.isActive);
  //     })
  //   ),
  handleAction(editRingSetting.success, (state, action) =>
    produce(state, draft => {
      const item = state.find(i => i.id === action.payload.id);
      if (!item) {
        return;
      }
      const itemIndex = draft.findIndex(i => i.id === action.payload.id);
      const newItem = {
        id: item.id,
        isActive: item.isActive,
        product: { ...action.payload.item, id: item.product.id, imageUrl: item.product.imageUrl },
      };
      draft[itemIndex] = newItem;
      return draft;
    })
  ),
  handleAction(fetchRingSettings.success, (state, action) => action.payload),

  handleAction(deleteRingSetting.success, (state, action) =>
    produce(state, draft => {
      const newRingArray = state.filter(r => r.product.id !== action.payload);
      draft = newRingArray;
      return draft;
    })
  ),
]);

const actions = {
  addRingSetting,
  editRingSetting,
  fetchRingSettings,
  deleteRingSetting,
  importCsv,
};

export { actions, reducer };
