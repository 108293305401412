import Axios from "axios";
import { authorizationHeader } from "./apiUtils";
import { Color, Country, IdNameModel, Shape } from "../models/misc";

const routes = {
  business_types: "/api/data/business_types",
  countries: "/api/data/countries",
  diamonds: {
    clarities: "https://www.alonavinoam.com/api/data/diamonds/clarities",
    colors: "https://www.alonavinoam.com/api/data/diamonds/colors",
    cuts: "https://www.alonavinoam.com/api/data/diamonds/cuts",
    fluors: "https://www.alonavinoam.com/api/data/diamonds/fluors",
    labs: "https://www.alonavinoam.com/api/data/diamonds/labs",
    polishes: "https://www.alonavinoam.com/api/data/diamonds/polishes",
    shapes: "https://www.alonavinoam.com/api/data/diamonds/shapes",
    symmetries: "https://www.alonavinoam.com/api/data/diamonds/symmetries",
    fancyIntensities: "https://www.alonavinoam.com/api/data/diamonds/fancyIntensities",
  },
};

const getBusinessTypes = async (): Promise<IdNameModel[]> =>
  (await Axios.get<IdNameModel[]>(routes.business_types)).data;

const getCountries = async (): Promise<Country[]> => (await Axios.get<Country[]>(routes.countries)).data;

const getClarities = async (): Promise<IdNameModel[]> =>
  (
    await Axios.get<IdNameModel[]>(routes.diamonds.clarities, {
      headers: authorizationHeader(),
    })
  ).data;

const getColors = async (): Promise<Color[]> =>
  (
    await Axios.get<Color[]>(routes.diamonds.colors, {
      headers: authorizationHeader(),
    })
  ).data;

const getCuts = async (): Promise<IdNameModel[]> =>
  (
    await Axios.get<IdNameModel[]>(routes.diamonds.cuts, {
      headers: authorizationHeader(),
    })
  ).data;

const getFluors = async (): Promise<IdNameModel[]> =>
  (
    await Axios.get<IdNameModel[]>(routes.diamonds.fluors, {
      headers: authorizationHeader(),
    })
  ).data;

const getLabs = async (): Promise<IdNameModel[]> =>
  (
    await Axios.get<IdNameModel[]>(routes.diamonds.labs, {
      headers: authorizationHeader(),
    })
  ).data;

const getPolishes = async (): Promise<IdNameModel[]> =>
  (
    await Axios.get<IdNameModel[]>(routes.diamonds.polishes, {
      headers: authorizationHeader(),
    })
  ).data;

const getShapes = async (): Promise<Shape[]> =>
  (
    await Axios.get<Shape[]>(routes.diamonds.shapes, {
      headers: authorizationHeader(),
    })
  ).data;

const getSymmetries = async (): Promise<IdNameModel[]> =>
  (
    await Axios.get<IdNameModel[]>(routes.diamonds.symmetries, {
      headers: authorizationHeader(),
    })
  ).data;

const getFancyIntensities = async (): Promise<IdNameModel[]> =>
    (
      await Axios.get<IdNameModel[]>(routes.diamonds.fancyIntensities,{
        headers: authorizationHeader()
      })
    ).data;

const diamonds = {
  getClarities,
  getColors,
  getCuts,
  getFluors,
  getLabs,
  getPolishes,
  getShapes,
  getSymmetries,
  getFancyIntensities,
};

export { diamonds, getBusinessTypes, getCountries };
