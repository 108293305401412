import { Dispatch } from "redux";
import { EternityRing } from "../models/products";
import { createActionCreator, createReducer } from "deox";
import * as eternityRingsService from "../services/eternityRings";

export type State = {
  data: EternityRing[] | null;
};

const defaultState: State = {
  data: null,
};

const fetchEternityRings = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await eternityRingsService.getAllEternityRings();
      dispatch(fetchEternityRings.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ETERNITY_RINGS/FETCH", resolve => (data: EternityRing[]) => resolve(data)),
  }
);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchEternityRings.success, (state, action) => ({ ...state, data: action.payload })),
]);

const actions = {
  fetchEternityRings,
};

export { actions, reducer };
