import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Filter } from "../hooks/useFilters";
import { Order } from "../hooks/useSorting";

export type State = {
  [index in PageId]: PageUIState;
};

export type PageUIState = {
  filters: Filter<any>[];
  checkedItems: number[];
  sort: Sort;
  pagination: PaginationType;
};

export type Sort = {
  direction: Order;
  orderBy: string;
};

export type PaginationType = {
  pageNumber: number;
  rowsPerPage: number;
};

export type PageId =
  | "admin_products"
  | "admin_users"
  | "admin_orders"
  | "cart"
  | "userWishlistBox"
  | "admin_basket_items"
  | "studs";

const changeFilters = Object.assign(
  {},
  {
    add: createActionCreator("@@FILTERS/ADD", resolve => (data: { pageId: PageId; filter: Filter<any> }) =>
      resolve(data)
    ),
    remove: createActionCreator("@@FILTERS/REMOVE", resolve => (data: { pageId: PageId; filter: Filter<any> }) =>
      resolve(data)
    ),
    reset: createActionCreator("@@FILTERS/RESET", resolve => (pageId: PageId) => resolve(pageId)),
  }
);

const checkProduct = Object.assign(
  {},
  {
    check: createActionCreator("@@CHECK/CHECK", resolve => (data: { diamondId: number; pageId: PageId }) =>
      resolve(data)
    ),

    remove: createActionCreator("@@CHECK/REMOVE", resolve => (data: { diamondId: number; pageId: PageId }) =>
      resolve(data)
    ),

    checkAll: createActionCreator("@@CHECK/CHECK_ALL", resolve => (data: { diamondIds: number[]; pageId: PageId }) =>
      resolve(data)
    ),

    removeAll: createActionCreator("@@CHECK/REMOVE_ALL", resolve => (pageId: PageId) => resolve(pageId)),
  }
);

const sortProduct = Object.assign(
  {},
  {
    setOrder: createActionCreator(
      "@@SORT/SET_ORDER",
      resolve => (data: { pageId: PageId; direction: Order; orderBy: string }) => resolve(data)
    ),
  }
);

const pagination = Object.assign(
  {},
  {
    setPage: createActionCreator("@@PAGINATION/SET_PAGE", resolve => (data: { pageId: PageId; pageNumber: number }) =>
      resolve(data)
    ),

    setRowsPerPage: createActionCreator(
      "@@PAGINATION/SET_ROWS_PER_PAGE",
      resolve => (data: { pageId: PageId; rowsPerPage: number }) => resolve(data)
    ),
  }
);

const defaultPageState: PageUIState = {
  filters: [],
  checkedItems: [],
  sort: { direction: "asc", orderBy: "carats" },
  pagination: { pageNumber: 0, rowsPerPage: 36 },
};

const defaultState: State = {
  admin_users: defaultPageState,
  admin_products: defaultPageState,
  admin_orders: defaultPageState,
  cart: defaultPageState,
  userWishlistBox: defaultPageState,
  admin_basket_items: defaultPageState,
  studs: defaultPageState,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeFilters.add, (state, action) =>
    produce(state, draft => {
      const filters = state[action.payload.pageId].filters || [];
      const newFilters = [
        ...filters.filter(f => !(f.key === action.payload.filter.key && f.type === action.payload.filter.type)),
        action.payload.filter,
      ];

      draft[action.payload.pageId].filters = newFilters;
    })
  ),
  handleAction(changeFilters.remove, (state, action) =>
    produce(state, draft => {
      const filters = state[action.payload.pageId].filters || [];
      const newFilters = [
        ...filters.filter(f => !(f.key === action.payload.filter.key && f.type === action.payload.filter.type)),
      ];

      draft[action.payload.pageId].filters = newFilters;
    })
  ),
  handleAction(changeFilters.reset, (state, action) => ({
    ...state,
    [action.payload]: { ...state[action.payload], filters: [] },
  })),

  handleAction(checkProduct.check, (state, action) =>
    produce(state, draft => {
      const checkedItems = [
        ...state[action.payload.pageId].checkedItems.filter(el => el !== action.payload.diamondId),
        action.payload.diamondId,
      ];
      draft[action.payload.pageId].checkedItems = checkedItems;
    })
  ),

  handleAction(checkProduct.remove, (state, action) =>
    produce(state, draft => {
      const checkedItems = [...state[action.payload.pageId].checkedItems.filter(el => el !== action.payload.diamondId)];
      draft[action.payload.pageId].checkedItems = checkedItems;
    })
  ),

  handleAction(checkProduct.checkAll, (state, action) =>
    produce(state, draft => {
      const checkedItemsNew = [...action.payload.diamondIds];
      // const checkedItemsCurrent = state[action.payload.pageId].checkedItems;
      draft[action.payload.pageId].checkedItems = checkedItemsNew;
    })
  ),

  handleAction(checkProduct.removeAll, (state, action) =>
    produce(state, draft => {
      draft[action.payload].checkedItems = [];
    })
  ),

  handleAction(sortProduct.setOrder, (state, action) =>
    produce(state, draft => {
      draft[action.payload.pageId].sort = {
        direction: action.payload.direction,
        orderBy: action.payload.orderBy,
      };
    })
  ),

  handleAction(pagination.setPage, (state, action) =>
    produce(state, draft => {
      draft[action.payload.pageId].pagination.pageNumber = action.payload.pageNumber;
    })
  ),

  handleAction(pagination.setRowsPerPage, (state, action) =>
    produce(state, draft => {
      draft[action.payload.pageId].pagination.rowsPerPage = action.payload.rowsPerPage;
    })
  ),
]);

const actions = {
  changeFilters,
  checkProduct,
  sortProduct,
  pagination,
};

export { actions, reducer };
