import Axios from "axios";
import { CartItem } from "../models/cart";
import { authorizationHeader } from "./apiUtils";

const routes = {
  addDiamond: "/api/cart/diamond",
  addCompletedRing: "/api/cart/completedRing",
  addEngagementRing: "/api/cart/engagementRing",
  addEternityRing: "/api/cart/eternityRing",
  addBracelet: "/api/cart/bracelet",
  addSet: "/api/cart/add-set",
  addProduct: "/api/cart/add",
  addMelee: "/api/cart/melee",
  bulkAddDiamonds: "/api/cart/diamond-bulk",
  bulkRemoveDiamonds: "/api/cart/diamond-bulk-remove",
  bulkAddBasket: "/api/cart/basket-bulk-add",
  cart: "/api/cart",
  deleteSet: "/api/cart/remove-set",
};

const getCartItems = async (): Promise<CartItem[]> => {
  const result = await Axios.get<CartItem[]>(routes.cart, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const addDiamondToCart = async (itemId: number, wishedItemId?: number): Promise<CartItem> => {
  if (wishedItemId) {
    const result = await Axios.post(
      routes.addDiamond,
      { itemId, wishedItemId },
      {
        headers: authorizationHeader(),
      }
    );

    return result.data;
  }
  const result = await Axios.post(
    routes.addDiamond,
    { itemId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addEngagementRingToCart = async (
  totalPrice: number,
  wishedItemId: number,
  engagementRingId: number
): Promise<CartItem> => {
  const result = await Axios.post(
    routes.addEngagementRing,
    { totalPrice, wishedItemId, engagementRingId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addEternityRingToCart = async (wishedItemId: number, eternityRingId: number): Promise<CartItem> => {
  const result = await Axios.post(
    routes.addEternityRing,
    { wishedItemId, eternityRingId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addBraceletToCart = async (wishedItemId: number, braceletId: number): Promise<CartItem> => {
  const result = await Axios.post(
    routes.addBracelet,
    { wishedItemId, braceletId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addCompletedRingToCart = async (
  totalPrice: number,
  wishedItemId: number,
  completedRingId: number
): Promise<CartItem> => {
  const result = await Axios.post(
    routes.addCompletedRing,
    { totalPrice, wishedItemId, completedRingId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addSetToCart = async (itemId: number, wishedItemId?: number): Promise<CartItem[]> => {
  const result = await Axios.post(
    routes.addSet,
    { itemId, wishedItemId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addMeleeToCart = async (
  itemId: number,
  numberOfStones?: number,
  carats?: number,
  wishedItemId?: number
): Promise<CartItem> => {
  const result = await Axios.post(
    routes.addMelee,
    { itemId, numberOfStones, carats, wishedItemId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const bulkAddDiamondsToCart = async (itemIds: number[]): Promise<CartItem[]> => {
  const result = await Axios.post(
    routes.bulkAddDiamonds,
    itemIds.map(id => ({ itemId: id })),
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};
const bulkRemoveDiamondsFromCart = async (itemIds: number[]): Promise<CartItem[]> => {
  const result = await Axios.post(
    routes.bulkRemoveDiamonds,
    itemIds.map(id => ({ itemId: id })),
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addProductToCart = async (itemId: number): Promise<CartItem> => {
  const body = {
    itemId,
  };
  const result = await Axios.post(`${routes.addProduct}`, body, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const deleteCartItem = async (itemId: number): Promise<void> =>
  await Axios.delete(`${routes.cart}/${itemId}`, {
    headers: authorizationHeader(),
  });
const deleteSet = async (itemId: number): Promise<void> =>
  await Axios.delete(`${routes.deleteSet}/${itemId}`, {
    headers: authorizationHeader(),
  });

export {
  addDiamondToCart,
  addCompletedRingToCart,
  addEngagementRingToCart,
  addEternityRingToCart,
  addBraceletToCart,
  addSetToCart,
  addMeleeToCart,
  bulkAddDiamondsToCart,
  deleteCartItem,
  getCartItems,
  bulkRemoveDiamondsFromCart,
  addProductToCart,
  deleteSet,
};
