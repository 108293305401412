import Axios from "axios";
import { NumberFormat } from "xlsx/types";
import { WishListItem } from "../models/cart";
import { authorizationHeader } from "./apiUtils";

const routes = {
  wishList: "/api/wishlist",
  addMelee: "/api/wishlist/melee",
  addParcel: "/api/wishlist/parcel",
  addDiamond: "/api/wishlist/diamond",
  bulkAddDiamonds: "/api/wishlist/diamond-bulk",
  addDiamondsFromSet: "/api/wishlist/diamond-set",
  addCompletedRing: "/api/wishlist/completed-ring",
  addEngagementRing: "/api/wishlist/engagement-ring",
  addEternityRing: "/api/wishlist/eternity-ring",
  addBracelet: "/api/wishlist/bracelet",
  bulkRemoveDiamonds: "/api/wishlist/bulk-remove-basket-item",
  deleteDiamondFromBasketPage: "/api/wishlist/remove-basket-item",
  deleteDiamondsSetFromBasket: "/api/wishlist/remove-diamonds-set",
};

const getWishListItems = async (): Promise<WishListItem[]> => {
  const result = await Axios.get<WishListItem[]>(routes.wishList, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const addProductToWishList = async (itemId: number): Promise<WishListItem> => {
  const result = await Axios.post(
    routes.wishList,
    { itemId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addEngagementRingToWishList = async (body: any): Promise<WishListItem> => {
  const result = await Axios.post(routes.addEngagementRing, body, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const addCompletedRingToWishList = async (body: any): Promise<WishListItem> => {
  const result = await Axios.post(routes.addCompletedRing, body, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const addEternityRingToWishList = async (id: NumberFormat): Promise<WishListItem> => {
  const result = await Axios.post(
    routes.addEternityRing,
    { eternityRingId: id },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addBraceletToWishList = async (id: number): Promise<WishListItem> => {
  const result = await Axios.post(
    routes.addBracelet,
    { braceletId: id },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addDiamondsFromSetToWishList = async (itemIds: number[]): Promise<WishListItem[]> => {
  const result = await Axios.post(
    routes.addDiamondsFromSet,
    itemIds.map(id => ({ itemId: id })),
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addMeleeToWishList = async (itemId: number, numberOfStones?: number, carats?: number): Promise<WishListItem> => {
  const result = await Axios.post(
    routes.addMelee,
    { itemId, numberOfStones, carats },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};
const addParcelToWishList = async (
  itemId: number,
  numberOfStones?: number,
  ringSize?: number
): Promise<WishListItem> => {
  const result = await Axios.post(
    routes.addParcel,
    { itemId, numberOfStones, ringSize },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const bulkAddDiamondsToCart = async (itemIds: number[]): Promise<WishListItem[]> => {
  const result = await Axios.post(
    routes.bulkAddDiamonds,
    itemIds.map(id => ({ itemId: id })),
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};
const bulkRemoveDiamondsFromBasket = async (wishedIds: number[]): Promise<WishListItem[]> => {
  const result = await Axios.post(
    routes.bulkRemoveDiamonds,
    wishedIds.map(id => ({ wishedItemId: id })),
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const deleteWishListItem = async (itemId: number): Promise<void> =>
  await Axios.delete(`${routes.wishList}/${itemId}`, {
    headers: authorizationHeader(),
  });
  
const deleteWishListItemFromBasket = async (wishedItemId: number): Promise<void> => {
  await Axios.post(
    routes.deleteDiamondFromBasketPage,
    { wishedItemId },
    {
      headers: authorizationHeader(),
    }
  );
};

const deleteDiamondsSetFromBasket = async (itemId: number): Promise<void> =>
  await Axios.delete(`${routes.deleteDiamondsSetFromBasket}/${itemId}`, {
    headers: authorizationHeader(),
  });

export {
  getWishListItems,
  addMeleeToWishList,
  deleteWishListItem,
  addParcelToWishList,
  addProductToWishList,
  bulkAddDiamondsToCart,
  addCompletedRingToWishList,
  addEternityRingToWishList,
  addEngagementRingToWishList,
  addBraceletToWishList,
  deleteDiamondsSetFromBasket,
  deleteWishListItemFromBasket,
  bulkRemoveDiamondsFromBasket,
  addDiamondsFromSetToWishList,
};
export function bulkRemoveDiamondsToWishList(wishedIds: number[]) {
  throw new Error("Function not implemented.");
}
