import React from "react";
import { Helmet } from "react-helmet";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ApplicationState } from "../store/store";

type TProps = ConnectedProps<typeof connector> &
  RouteProps & {
    component: React.LazyExoticComponent<React.ComponentType<any>>;
    layout?: React.LazyExoticComponent<React.ComponentType<any>>;
    roles?: ["Access"] | ["Administrator"] | ["Access", "Administrator"];
    pageTitle: string;
    withLogos?: boolean;
  };

const PrivateRoute = ({
  component: Component,
  currentUser,
  layout: Layout,
  roles,
  withLogos,
  pageTitle,
  logoUrl,
  ...rest
}: TProps) => {
  // add condiotionals bellow
  return (
    <Route
      {...rest}
      render={props => {
        return currentUser &&
          (!roles || roles.some(r => currentUser?.role === r)) &&
          !currentUser?.accesses[0]?.labGrown &&
          !currentUser?.accesses[0]?.white &&
          !currentUser?.accesses[0]?.black &&
          !currentUser?.accesses[0]?.mathingPairs &&
          !currentUser?.accesses[0]?.melee &&
          !currentUser?.accesses[0]?.snP &&
          !currentUser?.accesses[0]?.fancyShape &&
          !currentUser?.accesses[0]?.fancyColor &&
          currentUser?.accesses?.length === 0 ? (
          Layout ? (
            <>
              <Helmet>
                <title>{`${pageTitle} ${!logoUrl ? "| Aglam" : ""}`}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
              </Helmet>
              <Layout withLogos={withLogos}>
                <Component {...props} />
              </Layout>
            </>
          ) : (
            <>
              <Helmet>
                <title>{`${pageTitle} ${!logoUrl ? "| Aglam" : ""}`}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
              </Helmet>
              <Component {...props} />
            </>
          )
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        );
      }}
    />
  );
};

const connector = connect(({ auth, domain }: ApplicationState) => ({
  currentUser: auth.user!,
  logoUrl: domain.data && domain.data.logoUrl,
}));

export default connector(PrivateRoute);
