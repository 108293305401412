import Axios from "axios";
import { EngagementRingConfig } from "../models/engagementRingConfig";
import { authorizationHeader } from "./apiUtils";

type EngagementRing = {
  diamond?: {
    stockId: string | null;
  };
  ringSetting?: {
    id: number | null;
  };
};

const routes = {
  getSingle: "/api/ring_config/single",
  updateRing: "/api/ring_config/update_ring",
  updateDiamond: "/api/ring_config/update_diamond",
};

const getUserEngagementRingConfig = async (): Promise<EngagementRingConfig> => {
  const route = `${routes.getSingle}`;

  const result = await Axios.get<EngagementRingConfig>(route, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const updateUserRingConfig = async (model: EngagementRing): Promise<void> => {
  const route = routes.updateRing;
  await Axios.post(route, model, {
    headers: authorizationHeader(),
  });
};

const updateUserDiamondConfig = async (model: EngagementRing): Promise<void> => {
  const route = routes.updateDiamond;
  await Axios.post(route, model, {
    headers: authorizationHeader(),
  });
};

// const addRingSetting = async (ringSetting: UpsertRingSetting): Promise<Product<RingSetting>> => {
//   const result = await Axios.post<Product<RingSetting>>(routes.ringSettings, ringSetting, {
//     headers: authorizationHeader(),
//   });

//   return result.data;
// };

export { getUserEngagementRingConfig, updateUserRingConfig, updateUserDiamondConfig };
