import { Dispatch } from "redux";
import { createActionCreator, createReducer } from "deox";
import * as productService from "../services/products";
import {
  Ring,
  Bracelet,
  Pendant,
  Necklace,
  Earring,
  OneOfAKindProduct,
  Stud,
  TennisNecklace,
} from "../models/products";

export type State = {
  singleProduct: Ring | Bracelet | Pendant | Necklace | Earring | Stud | TennisNecklace | null;
  oneOfAKind: OneOfAKindProduct[] | null;
  studs: OneOfAKindProduct[] | null;
  tennisNecklaces: OneOfAKindProduct[] | null;
};

const defaultState: State = {
  singleProduct: null,
  oneOfAKind: null,
  studs: null,
  tennisNecklaces: null,
};

const fetchAll = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await productService.getAll();
      dispatch(fetchAll.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@PRODUCTS/ALL/FETCH", resolve => (data: OneOfAKindProduct[]) => {
      const oneOfAKind = data.filter(
        product =>
          product.status === "Available" &&
          !product.subType &&
          (product.type === "Rings" ||
            product.type === "Necklaces" ||
            product.type === "Pendants" ||
            product.type === "Earrings" ||
            product.type === "Bracelets")
      );
      const studs = data.filter(product => product.type === "Studs" && product.status === "Available");
      const tennisNecklaces = data.filter(product => product.subType === "Tennis" && product.status === "Available");

      return resolve({ oneOfAKind, studs, tennisNecklaces });
    }),
  }
);

const fetchSingleProduct = Object.assign(
  (stockNumber: string) => async (dispatch: Dispatch) => {
    try {
      const result = await productService.getSingleProduct(stockNumber);
      dispatch(fetchSingleProduct.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator(
      "@@PRODUCTS/SINGLE_PRODUCT/FETCH",
      resolve => (data: Ring | Bracelet | Pendant | Necklace | Earring) => resolve(data)
    ),
  }
);

// Create a reducer to handle the fetch actions
const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchAll.success, (state, action) => ({ ...state, ...action.payload })),
  handleAction(fetchSingleProduct.success, (state, action) => ({ ...state, singleProduct: action.payload })),
]);

// Export fetch actions and reducer
const actions = {
  fetchAll,

  fetchSingleProduct,
};

export { actions, reducer };
