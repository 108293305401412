import Axios from "axios";
import { authorizationHeader } from "./apiUtils";
import { Product, RingSetting, UpsertRingSetting } from "../models/products";

const routes = {
  ringSettings: "/api/ring_settings",
  ringSetting: "/api/ring_settings/{id}",
  import: "/api/ring_settings/import",
  delete: "/api/ring_settings",
};

const getAllRingSettings = async (): Promise<Product<RingSetting>[]> => {
  const route = `${routes.ringSettings}`;

  const result = await Axios.get<Product<RingSetting>[]>(route, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const addRingSetting = async (ringSetting: UpsertRingSetting): Promise<Product<RingSetting>> => {
  const result = await Axios.post<Product<RingSetting>>(routes.ringSettings, ringSetting, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const deleteRingSetting = async (ringId: number): Promise<void> =>
  await Axios.delete(`${routes.delete}/${ringId}`, {
    headers: authorizationHeader(),
  });

const importCsv = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);

  await Axios.post(routes.import, formData, {
    headers: authorizationHeader(),
  });
};

const edit = async (id: number, model: UpsertRingSetting): Promise<void> => {
  const route = routes.ringSetting.replace("{id}", id.toString());
  await Axios.patch(route, model, {
    headers: authorizationHeader(),
  });
};

export { getAllRingSettings, addRingSetting, deleteRingSetting, edit, importCsv };
