import { GenericDiamond } from "./genericDiamond";
import { UploadFile } from "./misc";

export interface Product<TProductType> {
  id: number;
  product: TProductType;
  isActive: boolean;
}

enum DiamondStatusEnum {
  Available = 1,
  Hold = 2,
  Suspended = 3,
  Memo = 4,
}

enum MeleeColor {
  Black = 1,
  TTLB = 2,
  White = 3,
}

enum ProductType {
  Diamond = 1,
  Melee = 2,
  RingSetting = 3,
}

export interface TennisNecklace {
  id: number;
  stockNumber: string;
  status: string;
  type: string;
  subType: string;
  style: string;
  sizeCategory: string;
  metal: string;
  goldKarats: string;
  gemstone: string;
  secondaryGemstone: string;
  mainStoneShape: string;
  mainStoneColor: string;
  mainStoneClarity: string;
  mainStoneCut: string;
  certified: string;
  reference: string;
  location: string;
  diamondQty: number;
  diamondWeight: number;
  gemstoneQty: number;
  gemstoneWeight: number;
  metalWeight: number;
  grossWeight: number;
  vendor: string;
  vendorStockNum: string;
  brand: string;
  remarks: string;
  inStockQty: number;
  onMemoQty: number;
  onHandQty: number;
  cost: number;
  unitPrice1: number;
  unitPrice2: number;
  unitPrice3: number;
  cash: number;
  headlineDescription: string;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  video: string;
}

export interface Stud {
  id: number;
  stockNumber: string;
  status: string;
  type: string;
  subType: string;
  style: string;
  sizeCategory: string;
  metal: string;
  goldKarats: string;
  gemstone: string;
  secondaryGemstone: string;
  mainStoneShape: string;
  mainStoneColor: string;
  mainStoneClarity: string;
  mainStoneCut: string;
  certified: string;
  reference: string;
  location: string;
  diamondQty: number;
  diamondWeight: number;
  gemstoneQty: number;
  gemstoneWeight: number;
  metalWeight: number;
  grossWeight: number;
  vendor: string;
  vendorStockNum: string;
  brand: string;
  remarks: string;
  inStockQty: number;
  onMemoQty: number;
  onHandQty: number;
  cost: number;
  unitPrice1: number;
  unitPrice2: number;
  unitPrice3: number;
  cash: number;
  headlineDescription: string;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  video: string;
}

export interface OneOfAKindProduct {
  id: number;
  stockNumber: string;
  status: string;
  type: string;
  subType: string;
  style: string;
  sizeCategory: string;
  metal: string;
  goldKarats: string;
  gemstone: string;
  secondaryGemstone: string;
  mainStoneShape: string;
  mainStoneColor: string;
  mainStoneClarity: string;
  mainStoneCut: string;
  certified: string;
  reference: string;
  location: string;
  diamondQty: number;
  diamondWeight: number;
  gemstoneQty: number;
  gemstoneWeight: number;
  metalWeight: number;
  grossWeight: number;
  vendor: string;
  vendorStockNum: string;
  brand: string;
  remarks: string;
  inStockQty: number;
  onMemoQty: number;
  onHandQty: number;
  cost: number;
  unitPrice1: number;
  unitPrice2: number;
  unitPrice3: number;
  cash: number;
  headlineDescription: string;
  photo1?: string;
  photo2?: string;
  photo3?: string;
  photo4?: string;
  video: string;
}
export interface Ring {
  id: number;
  stockNumber: string;
  type: string;
  status: string;
  subType: string;
  style: string;
  sizeCategory: string;
  metal: string;
  goldKarats: string;
  gemstone: string;
  secondaryGemstone: string;
  mainStoneShape: string;
  mainStoneColor: string;
  mainStoneClarity: string;
  mainStoneCut: string;
  certified: string;
  reference: string;
  location: string;
  diamondQty: number;
  diamondWeight: number;
  gemstoneQty: number;
  gemstoneWeight: number;
  metalWeight: number;
  grossWeight: number;
  vendor: string;
  vendorStockNum: string;
  brand: string;
  remarks: string;
  inStockQty: number;
  onMemoQty: number;
  onHandQty: number;
  cost: number;
  unitPrice1: number;
  unitPrice2: number;
  unitPrice3: number;
  cash: number;
  headlineDescription: string;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  video: string;
}
export interface Bracelet {
  id: number;
  stockNumber: string;
  type: string;
  status: string;
  subType: string;
  style: string;
  sizeCategory: string;
  metal: string;
  goldKarats: string;
  gemstone: string;
  secondaryGemstone: string;
  mainStoneShape: string;
  mainStoneColor: string;
  mainStoneClarity: string;
  mainStoneCut: string;
  certified: string;
  reference: string;
  location: string;
  diamondQty: number;
  diamondWeight: number;
  gemstoneQty: number;
  gemstoneWeight: number;
  metalWeight: number;
  grossWeight: number;
  vendor: string;
  vendorStockNum: string;
  brand: string;
  remarks: string;
  inStockQty: number;
  onMemoQty: number;
  onHandQty: number;
  cost: number;
  unitPrice1: number;
  unitPrice2: number;
  unitPrice3: number;
  cash: number;
  headlineDescription: string;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  video: string;
}
export interface Pendant {
  id: number;
  stockNumber: string;
  type: string;
  status: string;
  subType: string;
  style: string;
  sizeCategory: string;
  metal: string;
  goldKarats: string;
  gemstone: string;
  secondaryGemstone: string;
  mainStoneShape: string;
  mainStoneColor: string;
  mainStoneClarity: string;
  mainStoneCut: string;
  certified: string;
  reference: string;
  location: string;
  diamondQty: number;
  diamondWeight: number;
  gemstoneQty: number;
  gemstoneWeight: number;
  metalWeight: number;
  grossWeight: number;
  vendor: string;
  vendorStockNum: string;
  brand: string;
  remarks: string;
  inStockQty: number;
  onMemoQty: number;
  onHandQty: number;
  cost: number;
  unitPrice1: number;
  unitPrice2: number;
  unitPrice3: number;
  cash: number;
  headlineDescription: string;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  video: string;
}

export interface Necklace {
  id: number;
  stockNumber: string;
  status: string;
  subType: string;
  type: string;
  style: string;
  sizeCategory: string;
  metal: string;
  goldKarats: string;
  gemstone: string;
  secondaryGemstone: string;
  mainStoneShape: string;
  mainStoneColor: string;
  mainStoneClarity: string;
  mainStoneCut: string;
  certified: string;
  reference: string;
  location: string;
  diamondQty: number;
  diamondWeight: number;
  gemstoneQty: number;
  gemstoneWeight: number;
  metalWeight: number;
  grossWeight: number;
  vendor: string;
  vendorStockNum: string;
  brand: string;
  remarks: string;
  inStockQty: number;
  onMemoQty: number;
  onHandQty: number;
  cost: number;
  unitPrice1: number;
  unitPrice2: number;
  unitPrice3: number;
  cash: number;
  headlineDescription: string;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  video: string;
}
export interface Earring {
  id: number;
  stockNumber: string;
  status: string;
  subType: string;
  type: string;
  style: string;
  sizeCategory: string;
  metal: string;
  goldKarats: string;
  gemstone: string;
  secondaryGemstone: string;
  mainStoneShape: string;
  mainStoneColor: string;
  mainStoneClarity: string;
  mainStoneCut: string;
  certified: string;
  reference: string;
  location: string;
  diamondQty: number;
  diamondWeight: number;
  gemstoneQty: number;
  gemstoneWeight: number;
  metalWeight: number;
  grossWeight: number;
  vendor: string;
  vendorStockNum: string;
  brand: string;
  remarks: string;
  inStockQty: number;
  onMemoQty: number;
  onHandQty: number;
  cost: number;
  unitPrice1: number;
  unitPrice2: number;
  unitPrice3: number;
  cash: number;
  headlineDescription: string;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  video: string;
}

export interface MeleeStone {
  id: number;
  carats: number;
  color: MeleeColor;
  isActive: boolean;
  pricePerCarat: number;
  sizeFrom: number;
  sizeTo: number;
  quantity?: number;
}

export type UpsertMeleeStone = Omit<MeleeStone, "isActive" | "id">;
export interface RingSetting {
  id: number;
  name: string;
  sku: string;
  width: number;
  carat: number;
  origin: string;
  clarity: string;
  color: string;
  imageUrl?: string;
  price: number;
  goldType: string;
  style: string;
  shape: string;
  metalType: string;
  metalWeight: number;
  sideStones: number;
  quantity: number;
  threeStonesSideCt: number;
  hiddenDiamondBasket: string;
  image2?: string;
  image3?: string;
  image4?: string;
  image5?: string;
  image6?: string;
  video?: string;
  description?: string;
  active: boolean;
}

// export type UpsertRingSetting = Omit<RingSetting, "isActive" | "id">;

export interface EternityRing {
  id: number;
  sku: string;
  shape: string;
  ringSize: number;
  numberOfStones: number;
  weightPerStone: number;
  approxTotalCt: number;
  totalPrice: number;
  full: boolean;
  ringModel: string;
  goldColor: string;
  color: string;
  clarity: string;
  quantity: number;
  picture1: string;
  picture2: string;
  picture3: string;
  video: string;
  description?: string;
  active: boolean;
  [key: string]: string | number | boolean | undefined;
}

export type DiamondForBasket = {
  stockId: string;
  qty: number;
  shape: string;
  carats: number;
  colorName: string;
  clarityName: string;
  cutName: string;
  polName: string;
  symName: string;
  depth: number;
  table: number;
  measurement: string;
  pricePerCarat: number;
  discount: string;
  totalPrice: number;
  lab: string;
  certificate: string;
  image: string;
  image3d: string;
};

export type DiamondsType = {
  id: number;
  stockId: string;
  carats: number;
  certificateNo?: string;
  certificateUrl?: string;
  clarityId?: number;
  colorId: number | MeleeColor;
  colorShade?: string;
  comment?: string;
  crownAngle?: number;
  crownHeight?: number;
  culetSize?: number;
  cutId?: number;
  depthPercent?: number;
  discount?: number;
  eyeClean?: string;
  fancyIntensity?: string;
  fluorId?: number;
  fluorescence?: string;
  fluorescenceColor?: string;
  girdle?: number;
  height: number;
  image3d?: string;
  set3D?: string;
  setImage?: string;
  imageUrl?: string;
  pic2?: string;
  pic3?: string;
  pic4?: string;
  isHighlighted?: boolean;
  labId: number;
  length: number;
  milky?: string;
  overtone?: string;
  parcelStones?: number;
  pavilionAngle?: number;
  pavilionDepth?: number;
  polishId?: number;
  pricePerCarat: number;
  rapPrice?: number;
  shapeId: number;
  status: DiamondStatusEnum;
  symmetryId?: number;
  tablePercent?: number;
  totalPrice: number;
  width: number;
  isMatchingPair: boolean;
  memoAccount?: string;
  memoPricePerCarat?: number;
  memoTotalPrice?: number;
  hold?: string;
  holdUserId?: string;
  memoNo?: number;
  memoDate?: string;
  memoRap?: number;
  isLine?: boolean;
  isLabGrown?: boolean;
  isSnP?: boolean;
  lineSet?: string;
  isPair?: boolean;
  wishListId?: number;
  tradeShow?: string;
  ringSize?: number;
  treatment?: string;
  showToAll?: string;
  lineSetColor?: string;
  lineSetClarity?: string;
  imageArray?: string[];
};

export type Diamond = {
  id: number;
  stockId: string;
  carats: number;
  color: string | MeleeColor;
  colorId: number;
  height: number;
  isActive: boolean;
  lab: string;
  labId: number;
  length: number;
  pricePerCarat: number;
  shape: string;
  shapeId: number;
  status: DiamondStatusEnum;
  totalPrice: number;
  width: number;
  isFancy: boolean;
  isMatchingPair: boolean;
  isMelee?: boolean;
  certificateNumber?: string;
  certificateUrl?: string;
  clarity?: string;
  clarityId?: number;
  colorShade?: string;
  colorVariation?: string;
  comment?: string;
  crownAngle?: number;
  crownHeight?: number;
  culetSize?: number;
  cut?: string;
  cutId?: number;
  depthPercent?: number;
  discount?: number;
  eyeClean?: string;
  fancyIntensity?: string;
  fluor?: string;
  fluorId?: number;
  fluorescence?: string;
  fluorescenceColor?: string;
  girdle?: number;
  image3d?: string;
  set3D?: string;
  setImage?: string;
  imageUrl?: string;
  pic2?: string;
  pic3?: string;
  pic4?: string;
  isHighlighted?: boolean;
  milky?: string;
  overtone?: string;
  parcelStones?: number;
  pavilionAngle?: number;
  pavilionDepth?: number;
  polish?: string;
  polishId?: number;
  rapPrice: number;
  symmetry?: string;
  symmetryId?: number;
  tablePercent?: number;
  memoAccount?: string;
  memoPricePerCarat?: number;
  memoTotalPrice?: number;
  hold?: string;
  holdUserId?: string;
  memoNo?: number;
  memoDate?: string;
  memoRap?: number;
  isLine?: boolean;
  isSnP?: boolean;
  isLabGrown?: boolean;
  lineSet?: string;
  isPair?: boolean;
  wishListId?: number;
  tradeShow?: string;
  ringSize?: number;
  treatment?: string;
  showToAll?: string;
  lineSetColor?: string;
  lineSetClarity?: string;
  imageArray?: string[];
};

//FIX ID ENGAGEMENT
export interface DiamondAndRing {
  id: number;
  type?: string;
  wishListId: number;
  completedRingId?: number;
  engagementRingId?: number;
  diamond?: Diamond;
  ringSetting?: RingSetting;
  genericDiamond?: GenericDiamond;
  ring?: EternityRing;
}

export type ExcludeProps =
  | "id"
  | "imageUrl"
  | "clarity"
  | "color"
  | "cut"
  | "fluor"
  | "lab"
  | "polish"
  | "shape"
  | "symmetry"
  | "isActive"
  | "status"
  | "totalPrice";

export type ExcludeRingProps = "isActive" | "id" | "imageUrl";

export type UpsertDiamondModel = Omit<DiamondsType, ExcludeProps> & {
  image?: UploadFile;
};
export type UpsertRingSetting = Omit<RingSetting, ExcludeRingProps> & {
  image?: UploadFile;
};

export type UpsertProduct = {
  stockNumber: string;
  status: string;
  type: string;
  subType: string;
  style: string;
  sizeCategory: string;
  metal: string;
  goldKarats: string;
  gemstone: string;
  secondaryGemstone: string;
  mainStoneShape: string;
  mainStoneColor: string;
  mainStoneClarity: string;
  mainStoneCut: string;
  certified: string;
  reference: string;
  location: string;
  diamondQty: number;
  diamondWeight: number;
  gemstoneQty: number;
  gemstoneWeight: number;
  metalWeight: number;
  grossWeight: number;
  vendor: string;
  vendorStockNum: string;
  brand: string;
  remarks: string;
  inStockQty: number;
  onMemoQty: number;
  onHandQty: number;
  cost: number;
  unitPrice1: number;
  unitPrice2: number;
  unitPrice3: number;
  cash: number;
  headlineDescription: string;
  image1?: UploadFile | string;
  image2?: UploadFile | string;
  image3?: UploadFile | string;
  image4?: UploadFile | string;
  videoFile?: UploadFile | string;
};

export type UpsertOrderInvoiceModel = {
  invoice?: UploadFile;
  orderId: number;
  userEmail: string | undefined;
  ccEmail?: string;
  title: string;
  description?: string;
};

export { DiamondStatusEnum, MeleeColor, ProductType };
