import { createReducer, createActionCreator } from "deox";
import { Dispatch } from "redux";
import { getDomainLayout } from "../services/siteSettings";

export type DomainLayout = {
  logoUrl: string;
  domainColor: string;
  address: string;
  phone: string;
  email: string;
  company: string;
  name: string;
};

export type State = {
  data: DomainLayout | null;
};

const fetchDomainLayout = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const data = await getDomainLayout();
      dispatch(fetchDomainLayout.success(data));
    } catch (e) {
      console.log(e);
    }
  },
  {
    success: createActionCreator("@@MISC/FETCH_DOMAIN_LAYOUT", resolve => (data: DomainLayout) => resolve(data)),
  }
);

const defaultState: State = {
  data: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchDomainLayout.success, (state, action) => ({
    ...state,
    data: action.payload ? action.payload : null,
  })),
]);

const actions = {
  fetchDomainLayout,
};

export { actions, reducer };
