import Axios from "axios";
import { authorizationHeader } from "./apiUtils";
import { GenericDiamond, UpsertGenericDiamond } from "../models/genericDiamond";

const routes = {
  genericDiamonds: "/api/generic_diamonds",
  genericDiamond: "/api/generic_diamonds/{id}",
  import: "/api/generic_diamonds/import",
};

const getAllGenericDiamonds = async (): Promise<GenericDiamond[]> => {
  const route = `${routes.genericDiamonds}`;

  const result = await Axios.get<GenericDiamond[]>(route, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const addGenericDiamond = async (diamond: UpsertGenericDiamond): Promise<UpsertGenericDiamond> => {
  const result = await Axios.post<UpsertGenericDiamond>(routes.genericDiamonds, diamond, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const editGenericDiamond = async (id: number, model: UpsertGenericDiamond): Promise<void> => {
  const route = routes.genericDiamond.replace("{id}", id.toString());
  await Axios.patch(route, model, {
    headers: authorizationHeader(),
  });
};

const importCsv = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);

  await Axios.post(routes.import, formData, {
    headers: authorizationHeader(),
  });
};


export { getAllGenericDiamonds, addGenericDiamond, editGenericDiamond, importCsv };
