import Axios from "axios";
import { authorizationHeader } from "./apiUtils";
import { EternityRing } from "../models/products";

const routes = {
  eternityRings: "/api/eternity_rings",
  // genericDiamond: "/api/generic_diamonds/{id}",
  import: "/api/eternity_rings/import",
};

const getAllEternityRings = async (): Promise<EternityRing[]> => {
  const route = `${routes.eternityRings}`;

  const result = await Axios.get<EternityRing[]>(route, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const deleteAllEternityRings = async () => {
  const route = `${routes.eternityRings}`;

  const result = await Axios.delete(route, {
    headers: authorizationHeader(),
  });

  return result.data;
};

// const addGenericDiamond = async (diamond: UpsertGenericDiamond): Promise<UpsertGenericDiamond> => {
//   const result = await Axios.post<UpsertGenericDiamond>(routes.genericDiamonds, diamond, {
//     headers: authorizationHeader(),
//   });

//   return result.data;
// };

// const editGenericDiamond = async (id: number, model: UpsertGenericDiamond): Promise<void> => {
//   const route = routes.genericDiamond.replace("{id}", id.toString());
//   await Axios.patch(route, model, {
//     headers: authorizationHeader(),
//   });
// };

const importCsv = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);

  await Axios.post(routes.import, formData, {
    headers: authorizationHeader(),
  });
};

export { getAllEternityRings, deleteAllEternityRings, importCsv };
