import { combineReducers } from "redux";
import * as data from "./data";
import * as diamondsManagement from "./diamondManagement";
import * as emailsManagement from "./emailsManagement";
import * as meleeManagement from "./meleeManagement";
import * as messages from "./messages";
import * as orders from "./orders";
import * as showManagement from "./showManagement";
import * as siteSettings from "./siteSettings";
import * as userManagement from "./userManagement";
import * as basketItems from "./basketItems";
import * as ringSettings from "./ringSettingManagement";
import * as genericDiamonds from "./genericDiamondsManagement";
import * as eternityRings from "./eternityRingsManagement";
import * as productManagement from "./productManagement";

export type State = {
  data: data.State;
  diamonds: diamondsManagement.State;
  emails: emailsManagement.State;
  melee: meleeManagement.State;
  messages: messages.State;
  orders: orders.State;
  shows: showManagement.State;
  site: siteSettings.State;
  users: userManagement.State;
  basketItems: basketItems.State;
  ringSettings: ringSettings.State;
  genericDiamonds: genericDiamonds.State;
  eternityRings: eternityRings.State;
  productManagement: productManagement.State;
};

const reducer = combineReducers<State>({
  data: data.reducer,
  diamonds: diamondsManagement.reducer,
  emails: emailsManagement.reducer,
  melee: meleeManagement.reducer,
  messages: messages.reducer,
  shows: showManagement.reducer,
  site: siteSettings.reducer,
  orders: orders.reducer,
  users: userManagement.reducer,
  basketItems: basketItems.reducer,
  ringSettings: ringSettings.reducer,
  genericDiamonds: genericDiamonds.reducer,
  eternityRings: eternityRings.reducer,
  productManagement: productManagement.reducer,
});

export { reducer };
