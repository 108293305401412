import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { DiamondStatusEnum, DiamondsType, Product, UpsertDiamondModel } from "../../models/products";
import * as diamondsService from "../../services/diamonds";
import { dataUrlToBase64 } from "../../utils";
import { actions as messageActions } from "./messages";
import { withErrorDispatch } from "./withErrorDispatch";

export type State = Product<DiamondsType>[];

const addDiamond = Object.assign(
  (diamond: UpsertDiamondModel) => {
    diamond.image?.content && (diamond.image.content = dataUrlToBase64(diamond.image.content));

    return withErrorDispatch(
      diamondsService.addDiamond(diamond),
      result => addDiamond.success(result),
      "Error adding a diamond"
    );
  },
  {
    success: createActionCreator("@@ADMIN/DIAMONDS/ADD", resolve => (diamond: Product<DiamondsType>) =>
      resolve(diamond)
    ),
  }
);

const changeActiveStatus = Object.assign(
  (diamondId: number, isActive: boolean) =>
    withErrorDispatch(
      diamondsService.changeActiveStatus(diamondId, isActive),
      () => changeActiveStatus.success({ diamondId, isActive }),
      "Error changing diamond status"
    ),
  {
    success: createActionCreator(
      "@@ADMIN/DIAMONDS/CHANGE_ACTIVE_STATUS",
      resolve => (newStatus: { diamondId: number; isActive: boolean }) => resolve(newStatus)
    ),
  }
);

const changeStatus = Object.assign(
  (diamondId: number, status: DiamondStatusEnum) =>
    withErrorDispatch(
      diamondsService.changeStatus(diamondId, status),
      () => changeStatus.success({ diamondId, status }),
      "Error changing diamond status"
    ),
  {
    success: createActionCreator(
      "@@ADMIN/DIAMONDS/CHANGE_STATUS",
      resolve => (newStatus: { diamondId: number; status: DiamondStatusEnum }) => resolve(newStatus)
    ),
  }
);

const fetchAllDiamonds = Object.assign(
  () =>
    withErrorDispatch(
      diamondsService.getAllDiamonds(false),
      data => fetchAllDiamonds.success(data),
      "Error fetching diamonds"
    ),
  {
    success: createActionCreator("@@ADMIN/DIAMONDS/FETCH", resolve => (diamonds: Product<DiamondsType>[]) =>
      resolve(diamonds)
    ),
  }
);

const highlight = Object.assign(
  (diamondId: number) =>
    withErrorDispatch(
      diamondsService.highlight(diamondId),
      () => highlight.success(diamondId),
      "Error highlighting diamond"
    ),
  {
    success: createActionCreator("@@ADMIN/DIAMONDS/HIGHTLIGHT", resolve => (diamondId: number) => resolve(diamondId)),
  }
);

const importCsv = Object.assign(
  (file: File) => async (dispatch: Dispatch) => {
    try {
      await diamondsService.importCsv(file);

      messageActions.commit("Import successul. The page will reload to show the updated data", "info")(dispatch);

      setTimeout(() => window.location.reload(), 2000);
    } catch {
      messageActions.commit("Error importing file", "error")(dispatch);
    }
  },
  {}
);

const updateDiamond = Object.assign(
  (diamondId: number, diamond: UpsertDiamondModel) => {
    diamond.image?.content && (diamond.image.content = dataUrlToBase64(diamond.image.content));

    return withErrorDispatch(
      diamondsService.updateDiamond(diamondId, diamond),
      result => updateDiamond.success(result),
      "Error updating diamond"
    );
  },
  {
    success: createActionCreator("@@ADMIN/DIAMONDS/UPDATE", resolve => (diamond: Product<DiamondsType>) =>
      resolve(diamond)
    ),
  }
);

const defaultState: State = [];

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchAllDiamonds.success, (state, action) => action.payload),
  handleAction(addDiamond.success, (state, action) => {
    return state.concat(action.payload);
  }),
  handleAction(changeActiveStatus.success, (state, action) =>
    produce(state, draft => {
      const product = draft.find(d => d.id === action.payload.diamondId);
      product && (product.isActive = action.payload.isActive);
    })
  ),
  handleAction(changeStatus.success, (state, action) =>
    produce(state, draft => {
      const product = draft.find(d => d.id === action.payload.diamondId);
      product && (product.product.status = action.payload.status);
    })
  ),
  handleAction(highlight.success, (state, action) =>
    produce(state, draft => {
      const products = draft.filter(p => p.id === action.payload || !!p.product.isHighlighted);

      const highlightedProduct = products.find(p => !!p.product.isHighlighted);
      highlightedProduct && (highlightedProduct.product.isHighlighted = false);

      const newDiamond = products.find(p => p.id === action.payload);
      newDiamond && newDiamond.id !== highlightedProduct?.id && (newDiamond.product.isHighlighted = true);
    })
  ),
  handleAction(updateDiamond.success, (state, action) => [
    ...state.filter(d => d.id !== action.payload.id),
    action.payload,
  ]),
]);

const actions = {
  addDiamond,
  changeActiveStatus,
  changeStatus,
  fetchAllDiamonds,
  highlight,
  importCsv,
  updateDiamond,
};

export { actions, reducer };
