import { produce } from "immer";
import { Dispatch } from "redux";
import { actions as messageActions } from "./messages";
import { createActionCreator, createReducer } from "deox";
import * as genericDiamondsService from "../../services/genericDiamonds";
import { GenericDiamond, UpsertGenericDiamond } from "../../models/genericDiamond";

export type State = GenericDiamond[];

const defaultState: State = [];

const fetchGenericDiamonds = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await genericDiamondsService.getAllGenericDiamonds();
      dispatch(fetchGenericDiamonds.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ADMIN/GENERIC_DIAMOND/FETCH", resolve => (genericDiamonds: GenericDiamond[]) =>
      resolve(genericDiamonds)
    ),
  }
);

const editGenericDiamond = Object.assign(
  (id: number | undefined, model: GenericDiamond) => async (dispatch: Dispatch) => {
    try {
      await genericDiamondsService.editGenericDiamond(id ? id : 0, model);
      dispatch(editGenericDiamond.success({ ...model, id: id }));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ADMIN/GENERIC_DIAMOND/EDIT", resolve => (data: GenericDiamond) => resolve(data)),
  }
);

const addGenericDiamond = Object.assign(
  (model: GenericDiamond) => async (dispatch: Dispatch) => {
    try {
      const result = await genericDiamondsService.addGenericDiamond(model);
      dispatch(addGenericDiamond.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ADMIN/GENERIC_DIAMOND/ADD", resolve => (data: GenericDiamond) => resolve(data)),
  }
);

const importCsv = Object.assign(
  (file: File) => async (dispatch: Dispatch) => {
    try {
      await genericDiamondsService.importCsv(file);

      messageActions.commit("Import successul. The page will reload to show the updated data", "info")(dispatch);

      setTimeout(() => window.location.reload(), 2000);
    } catch {
      messageActions.commit("Error importing file", "error")(dispatch);
    }
  },
  {}
);
const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchGenericDiamonds.success, (state, action) => action.payload),

  handleAction(addGenericDiamond.success, (state, action) => state.concat(action.payload)),

  handleAction(editGenericDiamond.success, (state, action) =>
    produce(state, draft => {
      const item = state.find(i => i.id === action.payload.id);
      if (!item) {
        return;
      }
      const itemIndex = draft.findIndex(i => i.id === action.payload.id);
      const newItem = {
        id: item.id,
        ...action.payload,
      };
      draft[itemIndex] = newItem;
      return draft;
    })
  ),
]);

const actions = {
  fetchGenericDiamonds,
  editGenericDiamond,
  addGenericDiamond,
  importCsv,
};

export { actions, reducer };
