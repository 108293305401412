import { UploadFile } from "./misc";
import { Bracelet, Diamond, DiamondAndRing, EternityRing, MeleeStone, ProductType } from "./products";
import { IUserProfile } from "./users";

enum OrderStatus {
  Processing,
  Cancelled,
  Payment,
  Delivery,
  Completed,
}

export interface OrderItem {
  productType: ProductType;
  productId: number;
  totalPrice: number;
  description?: string;
  completedRing?: DiamondAndRing;
  engagementRing?: DiamondAndRing;
  eternityRing?: EternityRing;
  completedRingId?: number;
  engagementRingId?: number;
  eternityRingId?: number;
}

export interface Order {
  id: number;
  completedDate: Date | string | null;
  creationDate: Date | string;
  discount?: number;
  status: OrderStatus;
  items: OrderItem[];
  totalPrice: number;
  userId: string;
  description: string;
}

export interface OrderItemViewModel {
  id: number;
  description?: string;
  type: ProductType;
  item: MeleeStone | Diamond | DiamondAndRing | null | undefined;
  totalPrice: number;
  completedRing?: DiamondAndRing;
  engagementRing?: DiamondAndRing;
  eternityRing?: EternityRing;
  bracelet?: Bracelet;
  completedRingId?: number;
  engagementRingId?: number;
  eternityRingId?: number;
  braceletId?: number;
}

export interface OrderViewModel {
  id: number;
  completedDate: Date | string | null;
  creationDate: Date | string;
  discount?: number;
  status: OrderStatus;
  items: OrderItemViewModel[];
  totalPrice: number;
  user: IUserProfile | null;
  description: string | null;
  invoice?: UploadFile | string | undefined | any;
  pdf?: UploadFile | string | undefined | any;
}

export interface MeleeOrderItem {
  itemId: number;
  numberOfStones?: number;
  carats?: number;
}

export interface UpsertOrder {
  discount?: number;
  newDiamondIds: number[];
  newMelees: MeleeOrderItem[];
  removedItems: number[];
  userId?: string;
}

export interface OrderEmailModel {
  emailTo: string;
  emailCc: string[];
  title: string;
  description: string;
  invoiceUrl: string;
}
export { OrderStatus };
