import { createReducer, createActionCreator } from "deox";
import { Dispatch } from "redux";
import { Product, RingSetting } from "../models/products";
import { ApplicationState } from "./store";
import { REFETCH_DATA_INTERVAL } from "../constants";
import * as ringService from "../services/ringSettings";

export type State = {
  all: Product<RingSetting>[] | null;
};

export const fetchRingSettings = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { ringSettings } = getState();

    if (!!ringSettings.all) {
      return;
    }

    try {
      const data = await ringService.getAllRingSettings();
      dispatch(fetchRingSettings.success(data));

      setInterval(async () => {
        const data = await ringService.getAllRingSettings();
        dispatch(fetchRingSettings.success(data));
      }, REFETCH_DATA_INTERVAL);
    } catch {
      return;
    }
  },
  {
    success: createActionCreator(
      "@@RINGSETTINGS/FETCH_ENGAGEMENT_RINGS",
      resolve => (data: Product<RingSetting>[] | null) => resolve(data)
    ),
  }
);

const defaultState: State = {
  all: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchRingSettings.success, (state, action) => ({ ...state, all: action.payload })),
]);

const actions = {
  fetchRingSettings,
};

export { actions, reducer };
