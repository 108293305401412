import React from "react";
import { Helmet } from "react-helmet";
import { connect, ConnectedProps } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { ApplicationState } from "../store/store";

type TProps = ConnectedProps<typeof connector> &
  RouteProps & {
    component: React.LazyExoticComponent<React.ComponentType<any>>;
    pageTitle: string;
    layout?: React.LazyExoticComponent<React.ComponentType<any>>;
    withLogos?: boolean;
  };

const PublicRoute = ({
  component: Component,
  layout: Layout,
  withLogos,
  pageTitle,
  logoUrl,
  currentUser,
  ...rest
}: TProps): JSX.Element => (
  <Route
    {...rest}
    render={props => {
      return Layout ? (
        <>
          <Helmet>
            <title>{`${pageTitle} ${!logoUrl ? "| Aglam" : ""}`}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
          </Helmet>
          <Layout withLogos={withLogos}>
            <Component {...props} />
          </Layout>
        </>
      ) : (
        <>
          <Helmet>
            <title>{`${pageTitle} ${!logoUrl ? "| Aglam" : ""}`}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
          </Helmet>
          <Component {...props} />
        </>
      );
    }}
  />
);

const connector = connect(({ domain, auth }: ApplicationState) => ({
  logoUrl: domain.data && domain.data.logoUrl,
  currentUser: auth.user,
}));

export default connector(PublicRoute);
