import Axios from "axios";
import { authorizationHeader } from "./apiUtils";
import {
  OneOfAKindProduct,
  Bracelet,
  Earring,
  Necklace,
  Pendant,
  Ring,
  TennisNecklace,
  Stud,
  UpsertProduct,
  Product,
} from "../models/products";

const routes = {
  products: "/api/products",
  product: "/api/products/{stockNumber}",
  import: "/api/products/import",
  export: "/api/products/export",
};

const getAll = async (): Promise<OneOfAKindProduct[] | TennisNecklace[] | Stud[]> => {
  const route = `${routes.products}?type=all`;
  const result = await Axios.get<OneOfAKindProduct[]>(route, {
    headers: authorizationHeader(),
  });
  return result.data;
};

const getSingleProduct = async (stockNumber: string): Promise<Ring | Earring | Necklace | Earring | Pendant> => {
  const route = `${routes.products}/${stockNumber}`;
  const result = await Axios.get<Ring | Earring | Bracelet | Necklace | Pendant | TennisNecklace | Stud>(route, {
    headers: authorizationHeader(),
  });
  return result.data;
};

const addProduct = async (product: UpsertProduct) => {
  try {
    product.status = product.inStockQty > 0 ? 'Available' : 'Unavailable';

    const result = await Axios.post(routes.products, product, {
      headers: authorizationHeader()
    });
    return result.data;
  } catch (error) {
    const err: any = error;
    throw new Error('Error posting the data: ' + err.message);

  }
};

const edit = async (stockNumber: string, model: UpsertProduct): Promise<OneOfAKindProduct> => {
  const route = routes.product.replace("{stockNumber}", stockNumber);
  const result = await Axios.patch(route, model, {
    headers: authorizationHeader(),
  });
  return result.data;
};

const delProduct = async (stockNumber: string) => {
  try {
    await Axios.delete(`${routes.products}/${stockNumber}`, {
      headers: authorizationHeader(),
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

const onExportCsv = async () => {
  try {
    const response = await Axios.get(routes.export, {
      headers: authorizationHeader(),
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'products.csv');

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    throw new Error("Error downloading file");
  }
};


const importCsv = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);

  await Axios.post(routes.import, formData, {
    headers: authorizationHeader(),
  });
};

export { getAll, getSingleProduct, addProduct, edit, delProduct, importCsv, onExportCsv };
