import { AnyAction, Dispatch } from "redux";
import { OneOfAKindProduct, UpsertProduct } from "../../models/products";
import { actions as messageActions } from "./messages";
import { createActionCreator, createReducer } from "deox";
import * as productsService from "../../services/products";
import { produce } from "immer";
import { ThunkDispatch } from "redux-thunk";

export type State = OneOfAKindProduct[];

const defaultState: State = [];

const fetchProducts = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await productsService.getAll();
      dispatch(fetchProducts.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ADMIN_PRODUCTS_FETCH", resolve => (data: OneOfAKindProduct[]) => resolve(data)),
  }
);

const addProduct = (model: UpsertProduct) => async (dispatch: Dispatch) => {
  try {
    const result = await productsService.addProduct(model);

    dispatch(addProduct.success(result));
  } catch (error) {
    console.error("Error adding product:", error);
  }
};

addProduct.success = createActionCreator("@@ADMIN_PRODUCTS/ADD", resolve => (product: OneOfAKindProduct) =>
  resolve(product)
);

const editProduct = Object.assign(
  (stockNumber: string, model: UpsertProduct) => async (dispatch: Dispatch) => {
    try {
      const result: OneOfAKindProduct = await productsService.edit(stockNumber, model);
      dispatch(editProduct.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@ADMIN_PRODUCTS_EDIT", resolve => (data: UpsertProduct) => resolve(data)),
  }
);

const deleteProduct = Object.assign(
  (stockNumber: string) => async (dispatch: Dispatch) => {
    try {
      await productsService.delProduct(stockNumber);
      dispatch(deleteProduct.success(stockNumber));
    } catch (error) {
      console.error('Error deleting the product:', error)
      return;
    }
  },
  
);

const importCsv = Object.assign(
  (file: File) => async (dispatch: Dispatch) => {
    try {
      await productsService.importCsv(file);
      messageActions.commit("Import successul. The page will reload to show the updated data", "info")(dispatch);
      setTimeout(() => window.location.reload(), 2000);
    } catch {
      messageActions.commit("Error importing file", "error")(dispatch);
    }
  },
  {}
);

const exportCsv = () => {
  return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    try {
      await productsService.onExportCsv();
    } catch {
      dispatch({ type: 'EXPORT_CSV_ERROR' });
    }
  };
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchProducts.success, (state, action) => action.payload),
  handleAction(addProduct.success, (state, action) => state.concat(action.payload)),
  handleAction(editProduct.success, (state, action) =>
    produce(state, draft => {
      const item = state.find(i => i.stockNumber === action.payload.stockNumber);

      if (!item) {
        return;
      }

      const itemIndex = draft.findIndex(i => i.stockNumber === action.payload.stockNumber);
      const newItem = {
        ...action.payload
      };

      draft[itemIndex] = newItem as OneOfAKindProduct;
      return draft;
    })
  ),
]);

const actions = {
  fetchProducts,
  editProduct,
  addProduct,
  deleteProduct,
  importCsv,
  exportCsv
};

export { actions, reducer };
